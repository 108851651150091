<template>
  <div class="index">
    <h1>{{$route.meta.title}}</h1>
    <div class="index-hd">
      <van-image v-if="icon" width="80" height="80" fit="cover" round :src="icon" />
      <van-image v-else width="80" height="80" fit="cover" round :src="require('@/assets/imgs/logo_default.png')" />
      <h3>{{ sh_name }}</h3>
    </div>
    <router-view />
  </div>
</template>

<script>
import {getMerchantInfoApi} from '@/api'
import {getUrlParams} from '@/utils'
export default {
  data() {
    return {
      sid: '',
      sh_name: '',
      icon: ''
    }
  },
  created() {
    if (getUrlParams('sid')) {
      this.sid = getUrlParams('sid')
    } else {
      this.sid = JSON.parse(window.localStorage.getItem('sh_info')).sid
    }
    // 获取商户信息
    this.getMerchantInfoData()
  },
  methods: {
    getMerchantInfoData() {
      getMerchantInfoApi({
        sid: this.sid,
      }).then(res => {
        console.log('商户信息：', res)
        this.sh_name = res.data.sh_name
        this.icon = res.data.icon
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.index {
  padding: 0 40px;
  h1 {
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .index-hd {
    display: flex;
    align-items: center;
    h3 {
      margin-left: 30px;
    }
  }
}
</style>
